
import Vue from 'vue';

export default Vue.extend({
	props: {
		auth: {
			type: Boolean
		}
	},
	data: () => ({
		menuItems: [
			// {
			// 	name: 'Data',
			// 	icon: 'dns',
			// 	route: '',
			// 	children: [
			// 		{
			// 			name: 'Artists',
			// 			route: '/manage/artists',
			// 		},
			// 		{
			// 			name: 'Packs',
			// 			route: '/manage/packs',
			// 		},
			// 		{
			// 			name: 'Videos',
			// 			route: '/manage/videos',
			// 		},
			// 		// {
			// 		// 	name: 'Images',
			// 		// 	route: '/manage/images',
			// 		// },
			// 		{
			// 			name: 'Subscriptions',
			// 			route: '/manage/subscriptions',
			// 			disabled: true,
			// 		},
			// 		{
			// 			name: 'Permissions',
			// 			route: '/manage/permissions',
			// 			disabled: true,
			// 		},
			// 		{
			// 			name: 'Users',
			// 			route: '/manage/users',
			// 			disabled: true,
			// 		},
			// 		{
			// 			name: 'Tags',
			// 			route: '/manage/tags',
			// 		},
			// 	],
			// },
			{
				name: 'Portal',
				route: '',
				icon: 'bubble_chart',
				children: [
					// {
					// 	name: 'Subscribers',
					// 	route: '/portal/subscribers',
					// },
					{
						name: 'CMS',
						route: '/portal/content',
					},
				],
			},
			{
				name: 'Core',
				route: '',
				icon: 'bubble_chart',
				children: [
					{
						name: 'User Activity',
						route: '/core/activity',
					},
					{
						name: 'Content Management',
						route: '/core/content',
					},
				],
			},
			{
				name: 'Documentation',
				route: '',
				icon: 'collections_bookmark',
				children: [
					{
						name: 'Docs site',
						href: 'https://docs.drumnow.app/',
					},
				],
			},
			{
				name: 'Links',
				route: '',
				icon: 'link',
				children: [
					{
						name: 'Portal',
						href: 'https://drumnow.com',
					},
					{
						name: 'Core',
						href: 'https://drumnow-core.appspot.com',
					},
					{
						name: 'Swarm Documentation',
						href: 'https://dn-swarm-dev.herokuapp.com/api/',
					},
				],
			},
		],
	}),
});

